<template>
  <v-card flat tile class="flexcard">
    <v-card-title v-if="showHeader" class="pb-2" style="padding-left: 15px">
      <span
        class="font-weight-medium grey--text text--darken-4 text-subtitle-2"
        >{{ $t("files") }}</span
      ><v-spacer />
      <v-btn
        class="my-auto"
        aria-label="cross"
        icon
        @click.native="closeDialog"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider v-if="showHeader"></v-divider>
    <v-card-text class="pa-0 grow">
      <v-container class="px-4 mb-16" :class="{ 'pt-4': showHeader }">
        <v-row no-gutters class="mb-3" :class="{ 'mt-5': showHeader }">
          <span
            class="font-weight-medium grey--text text--darken-4 text-subtitle-2"
            >{{ $t("attachFiles") }}</span
          ></v-row
        >
        <div id="app">
          <div>
            <form enctype="multipart/form-data" novalidate>
              <div class="dropbox">
                <v-row no-gutters>
                  <input
                    type="file"
                    id="file-upload"
                    multiple
                    :name="uploadFieldName"
                    :disabled="isSaving"
                    @change="
                      filesChange($event.target.name, $event.target.files)
                    "
                    accept="image/*"
                    class="input-file"
                  />

                  <img src="@/assets/images/upload.svg" class="px-4 mt-2" />

                  <v-col
                    :cols="$vuetify.breakpoint.smAndUp ? '6' : '9'"
                    class="mt-2"
                    :class="$vuetify.breakpoint.smAndUp ? 'ml-4' : ''"
                  >
                    <span
                      class="text-subtitle-2 font-weight-medium grey--text text--darken-4"
                    >
                      {{ $t("dragAndDropText") }}<br />
                      <span
                        style="font-size: 10px"
                        class="font-weight-regular grey--text text--darken-2"
                        >{{ $t("supportMaxSizeText") }}</span
                      >
                    </span>
                  </v-col>
                  <v-col
                    :cols="$vuetify.breakpoint.smAndUp ? '3' : '8'"
                    md="8"
                    lg="8"
                    :class="
                      $vuetify.breakpoint.smAndUp
                        ? 'mt-2 ml-16 pl-6'
                        : 'mb-2 ml-16'
                    "
                  >
                    <label for="file-upload" class="mt-2 custom-file-upload">{{
                      $t("chooseFiles")
                    }}</label>
                  </v-col>
                </v-row>
              </div>
            </form>
            <v-row class="mt-2">
              <v-col>
                <span
                  class="text-subtitle-2 font-weight-medium grey--text text--darken-4"
                >
                  Attachments
                </span>
              </v-col>
              <v-col class="d-flex justify-end">
                <v-btn
                  plain
                  class="text-none"
                  color="primary"
                  :disabled="!referenceAttachments.length"
                  @click="downloadAll"
                  ><v-icon>mdi-tray-arrow-down</v-icon> Download All</v-btn
                ></v-col
              >
              <v-col cols="12">
                <v-alert
                  v-if="isError"
                  type="error"
                  icon="mdi-alert-outline"
                  text
                  class="caption font-weight-regular py-3 px-5"
                  dismissible
                  ><span
                    class="font-weight-bold grey--text text--darken-4"
                    v-if="unsupportedFile"
                    >{{ unsupportedFile }}</span
                  >
                  <div>{{ fileErrorMsg }}</div>
                </v-alert>
              </v-col>
            </v-row>
            <div>
              <v-card flat class="my-4">
                <div class="mt-3" v-for="(file, i) of fileInfo" :key="i">
                  <v-chip
                    class="my-2"
                    color="blue darken-2"
                    text-color="white"
                    label
                    variant="outlined"
                    small
                  >
                    {{ file.name }}
                  </v-chip>
                  <span
                    style="font-size: 10px"
                    class="ml-4 font-weight-regular grey--text text--darken-2"
                  >
                    {{ formatFileSize(file.size) }}
                  </span>
                </div>
              </v-card>
              <v-card flat class="my-4">
                <div
                  class="mt-3"
                  v-for="(file, i) of referenceAttachments"
                  :key="i"
                >
                  <v-row>
                    <v-col cols="1"
                      ><span
                        ><v-icon class="mt-2" medium>mdi-image</v-icon></span
                      ></v-col
                    >
                    <v-col cols="8">
                      <div>
                        <div>{{ file.file }}</div>
                        <div>
                          <span
                            style="font-size: 10px"
                            class="font-weight-regular grey--text text--darken-2"
                          >
                            {{ formatFileSize(file.size) }}
                          </span>
                        </div>
                        <div>
                          <span
                            style="font-size: 10px"
                            class="font-weight-regular grey--text text--darken-2"
                          >
                            {{ formatDate(file.created_at) }} by
                            {{ getUserName(file.user_id) }}</span
                          >
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="3">
                      <v-btn @click="showDeleteDialog(file)" icon>
                        <v-icon>mdi-delete</v-icon></v-btn
                      >
                      <v-btn text @click="downloadImage(file)" icon>
                        <v-icon>mdi-download</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
              </v-card>
            </div>
          </div>
        </div>
      </v-container>
    </v-card-text>
    <v-dialog v-model="deleteDialogVisible" max-width="500px">
      <v-card>
        <v-card-title>Delete Attachment</v-card-title>
        <v-divider></v-divider>

        <v-card-text class="py-2">
          Are you sure you want to delete this file?
        </v-card-text>

        <v-card-actions :class="$vuetify.breakpoint.smAndDown ? 'px-4' : ''">
          <v-spacer></v-spacer>
          <v-btn
            outlined
            class="text-none rounded-lg elevation-0"
            depressed
            :width="$vuetify.breakpoint.smAndDown ? '50%' : 90"
            @click="closeDeleteDialog"
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            class="primary text-none rounded-lg elevation-0"
            :width="$vuetify.breakpoint.smAndDown ? '50%' : 90"
            depressed
            :disabled="deleteInProgress"
            @click="deleteUploadedFiles"
          >
            {{ $t("delete")
            }}<v-progress-circular
              v-if="deleteInProgress"
              indeterminate
              size="20"
              color="white"
              class="ml-2"
            ></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay
      v-model="progerssOverlay"
      contained
      class="align-center justify-center"
    >
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-card>
</template>
<script>
import { bus } from "@/main";
import JSZip from "jszip";
const STATUS_INITIAL = 0,
  STATUS_SAVING = 1;
export default {
  name: "FileUpload",
  data() {
    return {
      uploadedFiles: [],
      uploadError: null,
      currentStatus: null,
      uploadFieldName: "photos",
      fileInfo: [],
      referenceAttachments: [],
      maxTotalFileSize: 40 * 1024 * 1024,
      isError: false,
      deleteDialogVisible: false,
      selectedItem: null,
      deleteInProgress: false,
      progerssOverlay: false,
      unsupportedFileTypes: [
        "gif",
        "mp4",
        "mp3",
        "avi",
        "mov",
        "exe",
        "msi",
        "bat",
      ],
      fileErrorMsg: "",
      unsupportedFile: null,
    };
  },
  props: {
    referenceId: {
      type: String,
      default: "",
    },
    referenceType: {
      type: String,
      default: "project",
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    reset() {
      this.currentStatus = STATUS_INITIAL;
      this.uploadedFiles = [];
      this.uploadError = null;
    },
    async filesChange(fieldName, fileList) {
      this.isError = false;
      this.fileErrorMsg = "";
      this.unsupportedFile = null;

      for (const file of fileList) {
        const fileExtension = file.name.split(".").pop().toLowerCase();
        if (this.unsupportedFileTypes.includes(fileExtension)) {
          this.isError = true;
          this.fileErrorMsg = "Sorry, this file type not supported.";
          this.unsupportedFile = file.name;
          return;
        }
        this.fileInfo.push(file);

        const totalSize = Array.from(this.fileInfo).reduce(
          (total, file) => total + file.size,
          0,
        );
        const combinedSize =
          totalSize + this.calculateTotalFileSize(this.fileInfo);

        // Check if the combined total size exceeds the limit
        if (combinedSize > this.maxTotalFileSize) {
          this.isError = true;
          this.fileErrorMsg = "Max file size upto 40MB";
          return;
        }
      }
      await this.uploadFiles(this.fileInfo, this.referenceId);
      this.fileInfo = [];
    },
    closeDialog() {
      this.projectDescription = "";
      this.projectName = "";
      this.scheduledStartDate = "";
      this.date = "";
      this.$emit("hideUploadFiles");
    },
    async getAttachmentsByReferenceId() {
      const payload = {};
      payload.referenceId = this.referenceId;
      const attachments = await this.$store.dispatch(
        "getAttachmentsByReferenceId",
        payload,
      );
      if (attachments.status !== "error") {
        this.referenceAttachments = attachments;
      } else {
        console.log("error");
      }
    },
    calculateTotalFileSize(files) {
      return files.reduce((total, file) => total + file.size, 0);
    },
    async uploadFiles(files, projectId) {
      const tokenResp = await this.$store.dispatch(
        "getAuthTokenForAttachments",
      );
      let token;
      if (tokenResp.status !== "error") {
        token = tokenResp.token;
      }
      this.progerssOverlay = true;
      const uploadedFileDetails = [];
      try {
        for (const file of files) {
          const payload = {};
          payload.file = file;
          payload.companyId = this.$store.state.User.user.company_id;
          payload.token = token;
          const uploadFileStatus = await this.$store.dispatch(
            "uploadImages",
            payload,
          );
          if (uploadFileStatus.status !== "error") {
            uploadedFileDetails.push(uploadFileStatus[0]);
          } else {
            //this.uploadError = true;
          }
        }
        if (uploadedFileDetails.length) {
          const payload = {
            attachments: uploadedFileDetails.map((item) => {
              return Object.assign({}, item, {
                reference_id: projectId,
                reference_type: this.referenceType,
                caption: this.caption,
                comments: this.comments,
              });
            }),
          };
          const saveAttachments = await this.$store.dispatch(
            "saveAttachmentData",
            payload,
          );
          if (saveAttachments.status !== "error") {
            const referenceAttachments = [
              ...this.referenceAttachments,
              ...saveAttachments,
            ];
            this.referenceAttachments = referenceAttachments;
            bus.$emit("projectFileUploaded", saveAttachments);
            this.progerssOverlay = false;
          } else {
            this.progerssOverlay = false;
            console.log("error in saving attachments");
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    showDeleteDialog(item) {
      this.selectedItem = item;
      this.deleteDialogVisible = true;
    },
    closeDeleteDialog() {
      this.selectedItem = null;
      this.deleteDialogVisible = false;
    },
    async deleteUploadedFiles() {
      this.deleteInProgress = true;
      let attachement_id;
      console.log(this.selectedItem);
      if (this.selectedItem) {
        attachement_id = [this.selectedItem.attachment_id];
      }
      let attachmentResp = await this.$store.dispatch("deleteAttachment", {
        attachmentIds: attachement_id,
      });
      if (attachmentResp.status !== "error") {
        const deletedIds = attachmentResp.map((item) => {
          return item.attachment_id;
        });
        const attachments = this.referenceAttachments.filter((item) => {
          return !deletedIds.includes(item.attachment_id);
        });
        this.referenceAttachments = attachments;
        bus.$emit("deleteProjectAttachments", attachmentResp);
      }
      this.deleteInProgress = false;
      this.selectedItem = null;
      this.closeDeleteDialog();
    },
    async downloadImage(item) {
      const downloadUrl = await this.getDownloadUrl(item);
      if (downloadUrl) {
        const response = await fetch(downloadUrl, {
          method: "GET",
          headers: {
            accept: "binary/octet-stream",
          },
        });

        if (response.ok) {
          const blob = await response.blob();
          // Create a temporary URL for the downloaded image
          const imageUrl = URL.createObjectURL(blob);

          // Trigger the download
          const link = document.createElement("a");
          link.href = imageUrl;
          link.download = item.file;
          link.click();

          // Clean up the temporary URL
          URL.revokeObjectURL(imageUrl);
        } else {
          console.error("Failed to download the image.");
        }
      } else {
        console.error("Failed to get the download URL.");
      }
    },
    async getDownloadUrl(item) {
      const attachmentServerUrl = process.env.VUE_APP_ATTACHMENTS_API;
      const imageUrl = item.url;
      const endpoint = `${attachmentServerUrl}/download/attachment?url=${encodeURIComponent(
        imageUrl,
      )}`;

      try {
        const response = await fetch(endpoint, {
          method: "GET",
          headers: {
            accept: "binary/octet-stream",
          },
        });

        if (!response.ok) {
          console.error(
            "Failed to get the download URL. Server responded with status:",
            response.status,
          );
          return null;
        }

        try {
          const blob = await response.blob();
          const downloadUrl = URL.createObjectURL(blob);
          return downloadUrl;
        } catch (error) {
          console.error("Failed to read the binary response.", error);
          return null;
        }
      } catch (error) {
        console.error("Network error. Failed to get the download URL.", error);
        return null;
      }
    },
    async downloadAll() {
      const items = this.referenceAttachments;
      const zip = new JSZip();
      this.progerssOverlay = true;
      for (const item of items) {
        const downloadUrl = await this.getDownloadUrl(item);
        if (downloadUrl) {
          const response = await fetch(downloadUrl, {
            method: "GET",
            headers: {
              accept: "binary/octet-stream",
            },
          });

          if (response.ok) {
            const blob = await response.blob();
            zip.file(item.file, blob);
          } else {
            console.error("Failed to download the image:", item.file);
          }
        } else {
          console.error("Failed to get the download URL:", item.file);
        }
      }

      // Generate a blob of the zip file
      const zipBlob = await zip.generateAsync({ type: "blob" });

      // Create a temporary URL for the zip file
      const zipUrl = URL.createObjectURL(zipBlob);

      // Trigger the download
      const link = document.createElement("a");
      link.href = zipUrl;
      link.download = "all_files.zip"; // You can specify the zip file name
      link.click();
      this.progerssOverlay = false;
      // Clean up the temporary URL
      URL.revokeObjectURL(zipUrl);
    },

    formatFileSize(fileSize) {
      return fileSize < 1024 * 1024
        ? (fileSize / 1024).toFixed(2) + " KB"
        : (fileSize / (1024 * 1024)).toFixed(2) + " MB";
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("T")[0].split("-");
      return `${month}/${day}/${year}`;
    },
    getUserName(userId) {
      const users = this.$store.state.User.companyUsers;
      const user = users.find((item) => {
        return item.user_id === userId;
      });
      return user.name;
    },
  },
  computed: {
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
  },

  mounted() {
    this.reset();
    this.getAttachmentsByReferenceId();
  },
};
</script>

<style lang="scss" scoped>
.v-select.v-input--dense .v-chip {
  margin: 8px 4px 0 4px;
}
.flexcard {
  display: flex;
  flex-direction: column;
}
.dropbox {
  outline: 1px dashed grey;
  background: #f5f5f5;
  color: #323232;
  min-height: 145px;
  position: relative;
  border-radius: 5px;
  cursor: pointer;
}
.input-file {
  opacity: 0;
  width: 100%;
  height: 85px !important;
  position: absolute;
  cursor: pointer;
}
.custom-file-upload {
  border: 1px solid #1976d2;
  border-radius: 8px;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  font-weight: 500;
  text-decoration: none;
  color: #1976d2;
}
</style>
