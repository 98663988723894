var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "flexcard", attrs: { flat: "", tile: "" } },
    [
      _vm.showHeader
        ? _c(
            "v-card-title",
            { staticClass: "pb-2", staticStyle: { "padding-left": "15px" } },
            [
              _c(
                "span",
                {
                  staticClass:
                    "font-weight-medium grey--text text--darken-4 text-subtitle-2",
                },
                [_vm._v(_vm._s(_vm.$t("files")))]
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "my-auto",
                  attrs: { "aria-label": "cross", icon: "" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.closeDialog.apply(null, arguments)
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.showHeader ? _c("v-divider") : _vm._e(),
      _c(
        "v-card-text",
        { staticClass: "pa-0 grow" },
        [
          _c(
            "v-container",
            { staticClass: "px-4 mb-16", class: { "pt-4": _vm.showHeader } },
            [
              _c(
                "v-row",
                {
                  staticClass: "mb-3",
                  class: { "mt-5": _vm.showHeader },
                  attrs: { "no-gutters": "" },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "font-weight-medium grey--text text--darken-4 text-subtitle-2",
                    },
                    [_vm._v(_vm._s(_vm.$t("attachFiles")))]
                  ),
                ]
              ),
              _c("div", { attrs: { id: "app" } }, [
                _c(
                  "div",
                  [
                    _c(
                      "form",
                      {
                        attrs: {
                          enctype: "multipart/form-data",
                          novalidate: "",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "dropbox" },
                          [
                            _c(
                              "v-row",
                              { attrs: { "no-gutters": "" } },
                              [
                                _c("input", {
                                  staticClass: "input-file",
                                  attrs: {
                                    type: "file",
                                    id: "file-upload",
                                    multiple: "",
                                    name: _vm.uploadFieldName,
                                    disabled: _vm.isSaving,
                                    accept: "image/*",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.filesChange(
                                        $event.target.name,
                                        $event.target.files
                                      )
                                    },
                                  },
                                }),
                                _c("img", {
                                  staticClass: "px-4 mt-2",
                                  attrs: {
                                    src: require("@/assets/images/upload.svg"),
                                  },
                                }),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "mt-2",
                                    class: _vm.$vuetify.breakpoint.smAndUp
                                      ? "ml-4"
                                      : "",
                                    attrs: {
                                      cols: _vm.$vuetify.breakpoint.smAndUp
                                        ? "6"
                                        : "9",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-subtitle-2 font-weight-medium grey--text text--darken-4",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("dragAndDropText"))
                                        ),
                                        _c("br"),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "font-weight-regular grey--text text--darken-2",
                                            staticStyle: {
                                              "font-size": "10px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("supportMaxSizeText")
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "v-col",
                                  {
                                    class: _vm.$vuetify.breakpoint.smAndUp
                                      ? "mt-2 ml-16 pl-6"
                                      : "mb-2 ml-16",
                                    attrs: {
                                      cols: _vm.$vuetify.breakpoint.smAndUp
                                        ? "3"
                                        : "8",
                                      md: "8",
                                      lg: "8",
                                    },
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "mt-2 custom-file-upload",
                                        attrs: { for: "file-upload" },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("chooseFiles")))]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "v-row",
                      { staticClass: "mt-2" },
                      [
                        _c("v-col", [
                          _c(
                            "span",
                            {
                              staticClass:
                                "text-subtitle-2 font-weight-medium grey--text text--darken-4",
                            },
                            [_vm._v(" Attachments ")]
                          ),
                        ]),
                        _c(
                          "v-col",
                          { staticClass: "d-flex justify-end" },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "text-none",
                                attrs: {
                                  plain: "",
                                  color: "primary",
                                  disabled: !_vm.referenceAttachments.length,
                                },
                                on: { click: _vm.downloadAll },
                              },
                              [
                                _c("v-icon", [_vm._v("mdi-tray-arrow-down")]),
                                _vm._v(" Download All"),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _vm.isError
                              ? _c(
                                  "v-alert",
                                  {
                                    staticClass:
                                      "caption font-weight-regular py-3 px-5",
                                    attrs: {
                                      type: "error",
                                      icon: "mdi-alert-outline",
                                      text: "",
                                      dismissible: "",
                                    },
                                  },
                                  [
                                    _vm.unsupportedFile
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "font-weight-bold grey--text text--darken-4",
                                          },
                                          [_vm._v(_vm._s(_vm.unsupportedFile))]
                                        )
                                      : _vm._e(),
                                    _c("div", [
                                      _vm._v(_vm._s(_vm.fileErrorMsg)),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "v-card",
                          { staticClass: "my-4", attrs: { flat: "" } },
                          _vm._l(_vm.fileInfo, function (file, i) {
                            return _c(
                              "div",
                              { key: i, staticClass: "mt-3" },
                              [
                                _c(
                                  "v-chip",
                                  {
                                    staticClass: "my-2",
                                    attrs: {
                                      color: "blue darken-2",
                                      "text-color": "white",
                                      label: "",
                                      variant: "outlined",
                                      small: "",
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(file.name) + " ")]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "ml-4 font-weight-regular grey--text text--darken-2",
                                    staticStyle: { "font-size": "10px" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.formatFileSize(file.size)) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                        _c(
                          "v-card",
                          { staticClass: "my-4", attrs: { flat: "" } },
                          _vm._l(_vm.referenceAttachments, function (file, i) {
                            return _c(
                              "div",
                              { key: i, staticClass: "mt-3" },
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c("v-col", { attrs: { cols: "1" } }, [
                                      _c(
                                        "span",
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "mt-2",
                                              attrs: { medium: "" },
                                            },
                                            [_vm._v("mdi-image")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c("v-col", { attrs: { cols: "8" } }, [
                                      _c("div", [
                                        _c("div", [_vm._v(_vm._s(file.file))]),
                                        _c("div", [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "font-weight-regular grey--text text--darken-2",
                                              staticStyle: {
                                                "font-size": "10px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.formatFileSize(
                                                      file.size
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c("div", [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "font-weight-regular grey--text text--darken-2",
                                              staticStyle: {
                                                "font-size": "10px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.formatDate(
                                                      file.created_at
                                                    )
                                                  ) +
                                                  " by " +
                                                  _vm._s(
                                                    _vm.getUserName(
                                                      file.user_id
                                                    )
                                                  )
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "3" } },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: { icon: "" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.showDeleteDialog(
                                                  file
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-delete"),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: { text: "", icon: "" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.downloadImage(file)
                                              },
                                            },
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-download"),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500px" },
          model: {
            value: _vm.deleteDialogVisible,
            callback: function ($$v) {
              _vm.deleteDialogVisible = $$v
            },
            expression: "deleteDialogVisible",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v("Delete Attachment")]),
              _c("v-divider"),
              _c("v-card-text", { staticClass: "py-2" }, [
                _vm._v(" Are you sure you want to delete this file? "),
              ]),
              _c(
                "v-card-actions",
                { class: _vm.$vuetify.breakpoint.smAndDown ? "px-4" : "" },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "text-none rounded-lg elevation-0",
                      attrs: {
                        outlined: "",
                        depressed: "",
                        width: _vm.$vuetify.breakpoint.smAndDown ? "50%" : 90,
                      },
                      on: { click: _vm.closeDeleteDialog },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "primary text-none rounded-lg elevation-0",
                      attrs: {
                        width: _vm.$vuetify.breakpoint.smAndDown ? "50%" : 90,
                        depressed: "",
                        disabled: _vm.deleteInProgress,
                      },
                      on: { click: _vm.deleteUploadedFiles },
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.$t("delete"))),
                      _vm.deleteInProgress
                        ? _c("v-progress-circular", {
                            staticClass: "ml-2",
                            attrs: {
                              indeterminate: "",
                              size: "20",
                              color: "white",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-overlay",
        {
          staticClass: "align-center justify-center",
          attrs: { contained: "" },
          model: {
            value: _vm.progerssOverlay,
            callback: function ($$v) {
              _vm.progerssOverlay = $$v
            },
            expression: "progerssOverlay",
          },
        },
        [
          _c("v-progress-circular", {
            attrs: { color: "primary", indeterminate: "", size: "64" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }